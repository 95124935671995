<template>
  <div v-loading="loading">
    <!-- 搜索 -->
    <div class="toolbar">
      物流公司：
      <el-input
        class=""
        placeholder="请输入名称"
        v-model="queryParam.companyName"
        size="medium"
        clearable
      >
      </el-input>
      启用状态
      <el-select v-model="queryParam.enableType" placeholder="请选择">
        <el-option label="全部" :value="0"></el-option>
        <el-option label="启用" :value="1"></el-option>
        <el-option label="未启用" :value="2"></el-option>
      </el-select>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-search"
        style="margin-left: 10px"
        @click="btn('query')"
        >查询</el-button
      >
      <el-button size="medium" @click="btn('reset')">重置</el-button>
    </div>
    <!-- 表格数据 -->
    <div class="right">
      <el-button type="primary" size="medium" @click="btn('add')"
        >新增</el-button
      >
    </div>
    <div class="divTb">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#cce1f9',
          color: '#505050',
          textAlign: 'center',
        }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%"
      >
        <el-table-column prop="companyId" label="编号"> </el-table-column>
        <el-table-column prop="name" label="公司名称"> </el-table-column>
        <el-table-column prop="contractorUser" label="承运人">
        </el-table-column>
        <el-table-column prop="mobile" label="联系方式"> </el-table-column>
        <el-table-column prop="remark" label="配送描述"> </el-table-column>
        <el-table-column prop="postTime" label="添加时间"> </el-table-column>
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.enable"
              active-text="开"
              inactive-text="关"
              @change="btn('enable', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="btn('edit', scope.row.companyId)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="btn('DisableToMerchant', scope.row.companyId)"
              >设置部分下架</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="btn('delete', scope.row.companyId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParam.index"
        :page-size="queryParam.size"
        layout="total, prev, pager, next"
        :total="queryParam.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="visible.dialog"
      @close="closeDialog"
    >
      <AddOrEdit
        :funcSucc="closeDialog"
        :editId="compParam.AddOrEdit.editId"
        v-if="visible.AddOrEdit"
      />
      <DisableToMerchant
        :funcSucc="closeDialog"
        :companyId="compParam.DisableToMerchant.companyId"
        v-if="visible.DisableToMerchant"
      />
    </el-dialog>
    <div id="nidiedi" style="width: 900px; height: 300px"></div>
  </div>
</template>

<script>
import request from "../../utils/request";
import baseURL from "../../utils/config";
//https://blog.csdn.net/weixin_43569405/article/details/102620973
import { GetList, Delete, SetEnable } from "@/api/Company";
import AddOrEdit from "@/components/LogisticsCompany/AddOrEdit";
import DisableToMerchant from "@/components/LogisticsCompany/DisableToMerchant";

const echarts = require("echarts")

export default {
  data() {
    return {
      loading: false,
      //查询参数
      queryParam: {
        companyName: "",
        enableType: 0,
        size: 10, //每页的数据量
        index: 1, //当前页数
        total: 1, //数据总量
      },
      //控制显示
      visible: {
        dialog: false,
        AddOrEdit: false,
        DisableToMerchant: false,
      },
      dialogTitle: "新增",
      //组件参数存放地
      compParam: {
        AddOrEdit: {
          editId: 1234,
        },
        DisableToMerchant: {
          companyId: 1234,
        },
      },
      tableData: [],
      currentPage: 1,
    };
  },
  components: {
    AddOrEdit,
    DisableToMerchant,
  },
  methods: {
    //pageSize 改变时会触发
    btn(btnType, val) {
      switch (btnType) {
        case "query":
          this.loadData();
          console.log("查询");
          break;
        case "reset":
          this.queryParam.companyName = "";
          this.queryParam.enableType = 0;
          console.log("重置");
          break;
        case "add":
          this.dialogTitle = "新增";
          this.compParam.AddOrEdit.editId = null;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("新增");
          break;
        case "edit":
          this.dialogTitle = "编辑";
          this.compParam.AddOrEdit.editId = val;
          this.visible.AddOrEdit = true;
          this.visible.dialog = true;
          console.log("编辑");
          break;
        case "delete":
          this.delete(val);
          console.log("删除");
          break;
        case "enable":
          this.Enable(val);
          console.log("启用");
          break;
        case "DisableToMerchant":
          this.dialogTitle = "设置部分下架";
          this.compParam.DisableToMerchant.companyId = val;
          this.visible.dialog = true;
          this.visible.DisableToMerchant = true;
          console.log("设置部分下架");
          break;
      }
    },
    closeDialog(val) {
      console.log("关闭");
      this.loadData();
      this.visible.dialog = false;
      this.visible.AddOrEdit = false;
      this.visible.DisableToMerchant = false;
    },
    Enable(val) {
      let tip = "确定启用吗";
      if (val.enable == false) {
        tip = "确定禁用吗";
      }

      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          SetEnable({ id: val.companyId, enable: val.enable })
            .then((res) => {
              this.loading = false;
              if (res.code == 10000) {
                this.$message.success("设置成功!");
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          val.enable = !val.enable;
        });
    },
    delete(id) {
      //删除
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        Delete({ id: id })
          .then((res) => {
            this.loading = false;
            if (res.code == 10000) {
              this.$message.success("删除成功!");
              this.loadData();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    loadData() {
      this.loading = true;
      let postData = {
        pageSize: this.queryParam.size,
        pageIndex: this.queryParam.index,
        queryStr: this.queryParam.companyName,
        state: this.queryParam.enableType,
      };
      GetList(postData)
        .then((res) => {
          if (res.code == 10000) {
            this.tableData = res.data;
            this.queryParam.total = res.total;
            this.loading = false;
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
      this.loading = false;
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {},
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.queryParam.index = val;
      this.loadData();
    },
    getPie() {
      // 绘制图表
       console.log("打的")
      console.log(echarts)
      var myChart = echarts.init(document.getElementById("nidiedi"));
      // 指定图表的配置项和数据
      var option = {
        //标题
        title: {
          text: "这是个标题",
          x: "left", //标题位置
          // textStyle: { //标题内容的样式
          //   color: '#000',
          //   fontStyle: 'normal',
          //   fontWeight: 100,
          //   fontSize: 16 //主题文字字体大小，默认为18px
          // },
        },
        // stillShowZeroSum: true,
        //鼠标划过时饼状图上显示的数据
        tooltip: {
          trigger: "item",
          formatter: "{a}<br/>{b}:{c} ({d}%)",
        },
        //图例
        legend: {
          //图例  标注各种颜色代表的模块
          // orient: 'vertical',//图例的显示方式  默认横向显示
          bottom: 10, //控制图例出现的距离  默认左上角
          left: "center", //控制图例的位置
          // itemWidth: 16,//图例颜色块的宽度和高度
          // itemHeight: 12,
          textStyle: {
            //图例中文字的样式
            color: "#000",
            fontSize: 16,
          },
          data: ["未领取", "处理中", "已完成"], //图例上显示的饼图各模块上的名字
        },
        //饼图中各模块的颜色
        color: ["#32dadd", "#b6a2de", "#5ab1ef"],
        // 饼图数据
        series: {
          // name: 'bug分布',
          type: "pie", //echarts图的类型   pie代表饼图
          radius: "70%", //饼图中饼状部分的大小所占整个父元素的百分比
          center: ["50%", "50%"], //整个饼图在整个父元素中的位置
          // data:''               //饼图数据
          data: [
            //每个模块的名字和值
            { name: "未领取", value: 10 },
            { name: "处理中", value: 30 },
            { name: "已完成", value: 50 },
          ],
          itemStyle: {
            normal: {
              label: {
                show: true, //饼图上是否出现标注文字 标注各模块代表什么  默认是true
                // position: 'inner',//控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
              },
              labelLine: {
                show: true, //官网demo里外部标注上的小细线的显示隐藏    默认显示
              },
            },
          },
        },
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
  created: function () {
    //如果是修改，就加载数据
    this.loadData();
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getPie();
    });
  },
};
</script>

<style scope>
.toolbar {
  background: #f2f2f2;
  padding: 10px;
  margin: 10px 0;
}
.divTb {
  margin-top: 20px;
}
.el-pagination {
  text-align: center;
  margin-top: 13px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
.right {
  float: right;
  padding-right: 50px;
  padding-bottom: 10px;
}
</style>